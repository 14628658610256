define(["knockout"],
    function(ko) {
        var SingleValueField = function(field, disabled) {
            var self = this;

            field = field || {};
            disabled = disabled || false;

            self.Name = field.Name;
            self.DisplayName = field.DisplayName ? field.DisplayName : field.Name;
            self.HiddenInputName = field.HiddenInputName;
            self.Value = ko.observable(field.Value);
            self.Hint = field.Hint;

            self.IsEnabled = ko.observable(!disabled);
        }
        SingleValueField.prototype.getDescription = function () {
            var self = this;
            var value = ko.unwrap(self.Value);
            if (!value) {
                return "";
            }
            return self.DisplayName + ": " + value;
        }
        SingleValueField.prototype.clear = function() {
            var self = this;
            self.Value(null);
        }
        SingleValueField.prototype.toJSON = function() {
            var copy = ko.toJS(this);
            if (copy.IsEnabled) {
                delete copy.Hint;
                delete copy.DisplayName;
                delete copy.IsEnabled;
                return copy;
            } else {
                return null;
            }
        }
        return SingleValueField;
    });