define(["jquery", "knockout", "jquery-tooltipster", "styles/custom/tooltipster-custom.css!"],
    function($, ko) {
        ko.bindingHandlers.tooltipster = {
            init: function(element, valueAccessor) {
                var position = "top-left";
                var delay = 100;
                var trigger = "hover";
                var value = ko.unwrap(valueAccessor());
                if (value) {
                    position = ko.unwrap(value
                            .position) ||
                        position; //right, left, top, top-right, top-left, bottom, bottom-right, bottom-left
                    delay = ko.unwrap(value.delay) || delay;
                    trigger = ko.unwrap(value.trigger) || trigger; //hover, click
                }
                $(element)
                    .tooltipster({
                        delay: delay,
                        position: position,
                        trigger: trigger
                    });
            },
            after: ["attr"]
        };
    });