define(
    [
        "knockout",
        "scripts/statistics/deposited-statistics",
        "scripts/statistics/draft-statistics"
    ],
    function(ko, DepositedStatistics, DraftStatistics) {
        var DocumentStatistics = function(statistics) {
            var self = this;
            statistics = statistics || {};
            self.DepositedStatistics = new DepositedStatistics(statistics.DepositedStatistics);
            self.DraftStatistics = new DraftStatistics(statistics.DraftStatistics);
            self.Licensed = ko.observable(statistics.Licensed || 0);
        }
        DocumentStatistics.prototype.refresh = function(data) {
            var self = this;
            data = data || {};
            self.DepositedStatistics.refresh(data.DepositedStatistics);
            self.DraftStatistics.refresh(data.DraftStatistics);
            self.Licensed(data.Licensed || 0);
        }
        return DocumentStatistics;
    }
);