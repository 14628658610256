define(["knockout"],
    function(ko) {
        var RangeField = function(field, disabled, compacted) {
            var self = this;

            field = field || {};
            disabled = disabled || false;
            compacted = compacted || false;

            self.Name = field.Name;
            self.DisplayName = field.DisplayName ? field.DisplayName : field.Name;
            self.HiddenInputNameMinValue = field.HiddenInputNameMinValue;
            self.HiddenInputNameMaxValue = field.HiddenInputNameMaxValue;
            self.MinValue = ko.observable(field.MinValue);
            self.MaxValue = ko.observable(field.MaxValue);
            self.Hint = field.Hint;

            self.IsCompacted = ko.observable(compacted && !field.MaxValue);
            self.IsEnabled = ko.observable(!disabled);

            self.MinValue.subscribe(function (newValue) {                
                // В компактном виде левая граница управляет обеими
                if (self.IsCompacted()) {
                    self.MaxValue(newValue);
                    return;
                }
                // Следим, чтобы левая граница была <= правой
                var maxValue = self.MaxValue();
                if (newValue !== null && maxValue != null && newValue > maxValue) {
                    self.MaxValue(newValue);
                }
            });
            self.MaxValue.subscribe(function (newValue) {
                if (newValue === null) {
                    return;
                }
                
                // Если не совпадаем с левой границей, разворачиваем
                if (self.IsCompacted()
                    && newValue != self.MinValue()) {
                    self.IsCompacted(false);
                }
                // Следим, чтобы левая граница была <= правой
                if (newValue < self.MinValue()) {
                    self.MinValue(newValue);
                }
            });
            self.IsCompacted.subscribe(function (newValue) {
                // Если сворачиваем поле, правая граница сводится к левой
                if (newValue) {
                    self.MaxValue(self.MinValue());
                }
                // А если разворачиваем, то обнуляем правую границу
                else {
                    self.MaxValue(null);
                }
            });
        }
        RangeField.prototype.showRange = function () {
            var self = this;
            self.IsCompacted(false);
        }
        RangeField.prototype.getDescription = function (formattingFunction) {
            var self = this;
            var minValue = ko.unwrap(self.MinValue), maxValue = ko.unwrap(self.MaxValue);
            if (!minValue && !maxValue) {
                return "";
            }
            if (typeof formattingFunction === "function") {
                minValue = formattingFunction(minValue);
                maxValue = formattingFunction(maxValue);
            }
            return self.DisplayName + ": " + minValue + " \u2013 " + maxValue;
        }
        RangeField.prototype.clear = function() {
            var self = this;
            self.MinValue(null);
            self.MaxValue(null);
        }
        RangeField.prototype.toJSON = function() {
            var copy = ko.toJS(this);
            if (copy.IsEnabled) {
                delete copy.Hint;
                delete copy.DisplayName;
                delete copy.IsEnabled;
                return copy;
            } else {
                return null;
            }
        };
        return RangeField;
    });