define(["knockout"],
    function(ko) {
        var DraftStatistics = function(statistics) {
            var self = this;
            statistics = statistics || {};
            self.Deleted = ko.observable(statistics.Deleted || 0);
            self.WithErrors = ko.observable(statistics.WithErrors || 0);
            self.InProgress = ko.observable(statistics.InProgress || 0);
            self.UserInteractionRequired = ko.observable(statistics.UserInteractionRequired || 0);
        }

        DraftStatistics.prototype.refresh = function(data) {
            var self = this;
            data = data || {};
            self.Deleted(data.Deleted || 0);
            self.WithErrors(data.WithErrors || 0);
            self.InProgress(data.InProgress || 0);
            self.UserInteractionRequired(data.UserInteractionRequired || 0);
        }

        return DraftStatistics;
    });