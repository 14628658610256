define(
    [
        "knockout",
        "jquery",
        "sa-utils",
        "blueimp-file-upload/js/jquery.fileupload",
        "blueimp-file-upload/js/jquery.iframe-transport",
        "blueimp-file-upload/js/jquery.fileupload-process",
        "blueimp-file-upload/js/jquery.fileupload-validate",
        "blueimp-file-upload/css/jquery.fileupload.css!"
    ],
    function(ko, $, utils) {
        var FileUpload = function(options, uploadCallback, errorCallback) {
            var self = this;

            self.AutoUpload = !!options.AutoUpload;
            self.ResultMessage = ko.observable();
            self.IsError = ko.observable(false);
            self.FileName = ko.observable();
            self.IsLoading = ko.observable(false);
            self.Progress = ko.observable();

            self.UploadCallback = uploadCallback;
            self.ErrorCallback = errorCallback;

            self.CanShowProgress = utils.featureDetection.xhr2;
            self.CanSubmit = options.CanSubmit
                ? options.CanSubmit
                : ko.pureComputed(function() {
                    return self.FileName() && !self.IsError();
                });

            self.cancelUploading = function() {
                return;
            }
            self.submit = function() {
                return;
            };

            self.reset = function() {
                self.ResultMessage(null);
                self.IsError(false);
                self.FileName(null);
                self.Progress(0);
            };
            self.init = function(el) {
                var uploadOptions = {
                    url: options.Url,
                    autoUpload: false,
                    dataType: "text",
                    maxFileSize: options.MaxFileSize ? options.MaxFileSize : 20 * 1024 * 1024,
                    acceptFileTypes: options.AcceptFileTypes ? options.AcceptFileTypes : /(\.|\/)(pdf|doc|docx|txt|rtf|html|odt|zip|rar|7z)$/i,
                    messages: {
                        acceptFileTypes: "Файл имеет недопустимый формат",
                        maxFileSize: "Файл превышает допустимый размер"
                    }
                }
                if (options.DropZoneSelector) {
                    var $dropZone = $(options.DropZoneSelector);
                    uploadOptions.dropZone = $dropZone;
                    $dropZone.bind("dragover",
                        function(e) {
                            $(this).addClass("dragging");
                        });
                    $dropZone.bind("dragleave drop",
                        function(e) {
                            $(this).removeClass("dragging");
                        });
                }
                $(el)
                    .fileupload(uploadOptions)
                    .on("fileuploadprocessalways",
                        function(e, data) {
                            var currentFile = data.files[data.index];
                            if (data.files.error && currentFile.error) {
                                self.IsError(true);
                                self.ResultMessage(currentFile.error);
                                self.cancelUploading = function() {
                                    return;
                                }
                                self.submit = function() {
                                    return;
                                };
                            } else {
                                self.IsError(false);
                                self.ResultMessage(null);

                                if (self.AutoUpload) {
                                    var jqXHR = data.submit();
                                    self.cancelUploading = function() {
                                        jqXHR.abort();
                                    }
                                } else {
                                    self.submit = function() {
                                        var jqXHR = data.submit();
                                        self.cancelUploading = function() {
                                            jqXHR.abort();
                                        }
                                    }
                                }
                            }
                        })
                    .on("fileuploadadd",
                        function(e, data) {
                            self.FileName(data.files[0].name);
                            data.process();
                        })
                    .on("fileuploaddone",
                        function(e, data) {
                            self.IsLoading(false);

                            var result = data.result;
                            try {
                                result = JSON.parse(result);
                            } catch (e) {
                                result = false;
                            }
                            var error = "Произошла ошибка при загрузке файла";
                            if (!result) {
                                self.IsError(true);                                
                                self.ResultMessage(error);
                                if (typeof self.ErrorCallback === "function") {
                                    self.ErrorCallback({ error: error });
                                }
                            } else if (!result.ok) {
                                self.IsError(true);
                                error = result.error || error;
                                self.ResultMessage(error);
                                if (typeof self.ErrorCallback === "function") {
                                    self.ErrorCallback({ error: error });
                                }
                            } else if (result.ok) {
                                self.IsError(false);
                                self.ResultMessage("Файл успешно загружен");
                                if (typeof self.UploadCallback === "function") {
                                    self.UploadCallback(result);
                                }
                            }
                        })
                    .on("fileuploadsubmit",
                        function(e, data) {
                            data.formData = ko.toJS(options.FormData);
                            self.IsLoading(true);
                        })
                    .on("fileuploadfail",
                        function(e, data) {
                            self.IsLoading(false);
                            if (data.errorThrown !== "abort") {
                                self.IsError(true);
                                self.ResultMessage(data.errorThrown);
                            }
                        })
                    .on("fileuploadprogressall",
                        function(e, data) {
                            self.Progress(parseInt(data.loaded / data.total * 100, 10) + "%");
                        });
            }
        }
        return FileUpload;
    }
);