define(["jquery", "knockout", "datetimepicker", "datetimepicker/build/jquery.datetimepicker.min.css!"],
    function($, ko) {
        ko.bindingHandlers.datetimepicker = {
            init: function(element, valueAccessor, allBindingsAccessor) {
                $.datetimepicker.setLocale("ru");

                var options = allBindingsAccessor().datetimepickerOptions || {},
                    $el = $(element);

                options.allowBlank = true;
                options.dayOfWeekStart = 1;
                options.onChangeDateTime = function() {
                    var observable = valueAccessor();
                    observable($el.datetimepicker("getValue"));
                };

                $el.datetimepicker(options);

                //handle disposal (if KO removes by the template binding)
                ko.utils.domNodeDisposal.addDisposeCallback(element,
                    function() {
                        $el.datetimepicker("destroy");
                    });

            },
            update: function(element, valueAccessor) {
                var value = ko.utils.unwrapObservable(valueAccessor()),
                    $el = $(element);

                if (!value) {
                    $el.val(null);
                    return;
                }

                //handle date data coming via json from Microsoft
                if (String(value).indexOf("/Date(") == 0) {
                    value = new Date(parseInt(value.replace(/\/Date\((.*?)\)\//gi, "$1")));
                }
                // Если дата пришла в виде строки
                if (!(value instanceof Date)) {
                    value = new Date(value);
                }

                var current = $el.datetimepicker("getValue");

                if (value - current !== 0) {
                    $el.datetimepicker({ value: value });
                }
            }
        };
    });