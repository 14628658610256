define(["knockout"],
    function(ko) {
        var UserStatistics = function(statistics) {
            var self = this;
            statistics = statistics || {};
            self.NonValidated = ko.observable(statistics.NonValidated || 0);
            self.Validated = ko.observable(statistics.Validated || 0);
            self.Online = ko.observable(statistics.Online || 0);
        }

        UserStatistics.prototype.refresh = function(data) {
            var self = this;
            data = data || {};
            self.NonValidated(data.NonValidated || 0);
            self.Validated(data.Validated || 0);
            self.Online(data.Online || 0);
        }

        return UserStatistics;
    });