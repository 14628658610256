define(["jquery", "knockout"],
    function($, ko) {
        var MultiValueField = function(field, disabled) {
            var self = this;

            field = field || {};
            disabled = disabled || false;

            self.Name = field.Name;
            self.DisplayName = field.DisplayName ? field.DisplayName : field.Name;
            self.HiddenInputName = field.HiddenInputName;
            self.HiddenInputNameCompositionType = field.HiddenInputNameCompositionType;
            self.CanChangeCompositionType = field.CanChangeCompositionType;
            self.Value = ko.observable();
            self.Values = ko.observableArray(field.Values ? field.Values : []);
            self.CompositionType = ko.observable(field.CompositionType);
            self.CompositionTypes = field.CompositionTypes;
            self.ValuesAvailable = field.ValuesAvailable;
            self.Hint = field.Hint;
            self.CompositionHint = field.CompositionHint;

            self.IsEnabled = ko.observable(!disabled);
        }
        MultiValueField.prototype.getDescription = function () {
            var self = this;
            var values = ko.unwrap(self.Values) || [];
            if (values.length === 0) {
                return "";
            }
            if (self.ValuesAvailable) {
                values = $.map(values,
                    function(val) {
                        var displayValue = ko.utils.arrayFirst(self.ValuesAvailable || [],
                            function(item) {
                                return item.Key === val;
                            });
                        return displayValue ? displayValue.Value : null;
                    });
            }
            return self.DisplayName + ": " + values.join(', ');
        }
        MultiValueField.prototype.clear = function() {
            var self = this;
            self.Value(null);
            self.Values([]);
        }
        MultiValueField.prototype.selectAll = function() {
            var self = this;
            self.Values($.map(self.ValuesAvailable,
                function(item) {
                    return item.Key;
                }));
        }
        MultiValueField.prototype.toJSON = function() {
            var self = this;
            if (self.IsEnabled) {
                return {
                    Name: self.Name,
                    Values: self.Values,
                    CompositionType: self.CompositionType
                };
            } else {
                return null;
            }
        };
        MultiValueField.prototype.addValue = function() {
            var self = this;
            var val = self.Value();
            if (self.ValuesAvailable && self.ValuesAvailable.length > 0) {
                var match = ko.utils.arrayFirst(self.ValuesAvailable,
                    function(item) {
                        return item.Key === val;
                    });
                if (!match)
                    return;
            }
            if (val != null && val != undefined && self.Values.length < 10 && self.Values.indexOf(val) < 0) {
                self.Values.push(val);
            }
        };
        MultiValueField.prototype.removeValue = function(word) {
            this.Values.remove(word);
        };
        MultiValueField.prototype.getValueDisplayName = function(value) {
            var self = this;

            var match = ko.utils.arrayFirst(self.ValuesAvailable,
                function(item) {
                    return item.Key === value;
                });

            if (match)
                return match.Value;
            else
                return "";
        };
        MultiValueField.prototype.setCompositionType = function(type) {
            var self = this;
            self.CompositionType(type);
        }

        return MultiValueField;
    });