define(
    [
        "scripts/main",
        "knockout",
        "jquery",
        "scripts/search/form",
        "scripts/statistics/statistics",
        "sa-utils",
        "scripts/home/index-bindings"
    ],
    function(main, ko, $, SearchForm, Statistics, utils) {
        var HomeViewModel = function(options) {
            var self = this;

            self.SearchForm = new SearchForm(options.initialData.Filter);            
            self.FormId = options.formId;

            self.IsLoading = ko.observable(false);
            self.RefreshTimeoutSuccess = options.refreshTimeoutSuccess;
            self.RefreshTimeoutError = options.refreshTimeoutError;
            self.Statistics = new Statistics();
            self.refreshStatistics();
        }
        HomeViewModel.prototype.search = function() {
            var self = this;
            if (!self.SearchForm.IsExtended()) {
                self.SearchForm.clearExtended();
            }
            // Приводим к UTC
            var formFields = self.SearchForm.FormFields;
            if (formFields.PublicationDate) {
                var maxDate = formFields.PublicationDate.MaxValue();
                if (maxDate && maxDate instanceof Date) {
                    formFields.PublicationDate.MaxValue(utils.transformDateToUtcString(maxDate));
                }
                var minDate = formFields.PublicationDate.MinValue();
                if (minDate && minDate instanceof Date) {
                    formFields.PublicationDate.MinValue(utils.transformDateToUtcString(minDate));
                }
            }
            $(self.FormId).submit();
        }
        HomeViewModel.prototype.refreshStatistics = function () {
            var self = this;
            if (self.IsLoading()) {
                return;
            }
            self.IsLoading(true);
            $.ajax(window.page.urls.Statistics.GetStatistics,
                {
                    headers: window.page.token,
                    type: "POST",
                    dataType: "json",
                    contentType: "application/json; charset=utf-8"
                })
                .done(function (result) {
                    if (utils.checkAndHandleCustomError(result)) {
                        setTimeout(self.refreshStatistics.bind(self), self.RefreshTimeoutError);
                        return;
                    }
                    self.Statistics.refresh(result.Result);
                    setTimeout(self.refreshStatistics.bind(self), self.RefreshTimeoutSuccess);
                })
                .fail(function (xhr, status, error) {
                    utils.handleAjaxError(xhr, status, error);
                    setTimeout(self.refreshStatistics.bind(self), self.RefreshTimeoutError);
                })
                .always(function () {
                    self.IsLoading(false);
                });
        }
        return HomeViewModel;
    }
);