define(["jquery", "knockout"],
    function($, ko) {
        ko.bindingHandlers.onEnter = {
            init: function(element, valueAccessor, allBindings, viewModel) {
                var callback = valueAccessor();
                $(element)
                    .keydown(function(event) {
                        var keyCode = (event.which ? event.which : event.keyCode);
                        if (keyCode === 13 && !event.shiftKey) {
                            callback.call(viewModel, viewModel);
                            return false;
                        }
                        return true;
                    });
            }
        };
    });