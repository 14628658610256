define(["jquery", "moment"],
    function($, moment) {
        moment.locale("ru");
        // Склонение названий месяцев с учетов диапазонов дат (замена для штатного метода moment.js)
        moment.localeData().months = function(m, format) {
            var months = {
                    'nominative': "январь_февраль_март_апрель_май_июнь_июль_август_сентябрь_октябрь_ноябрь_декабрь"
                        .split("_"),
                    'accusative': "января_февраля_марта_апреля_мая_июня_июля_августа_сентября_октября_ноября_декабря"
                        .split("_")
                },
                nounCase = format.indexOf("не раньше") >= 0 ||
                    format.indexOf("не позже") >= 0 ||
                    (/D[oD]?(\[[^\[\]]*\]|\s+)+MMMM?/).test(format)
                    ? "accusative"
                    : "nominative";
            return months[nounCase][m.month()];
        };
        // Для предотвращения ajax-ошибки при обновлении страницы,
        // переходе по ссылке и т.п. (см. Utils.handleAjaxError)
        window.isPageBeingRefreshed = false;

        window.isOldIE = (function() {
            var div = document.createElement("div");
            div.innerHTML = "<!--[if lt IE 9]><i></i><![endif]-->";
            return (div.getElementsByTagName("i").length === 1);
        }());

        $(window)
            .on(
                "beforeunload",
                function () {
                    window.isPageBeingRefreshed = true;
                });

        // Не даем браузеру открывать файлы при перетаскивании
        // на страницу (действие по умолчанию), не работает в <=IE8
        $(document)
            .on(
                "dragover",
                function(e) {
                    e.preventDefault();
                    e.stopPropagation();
                }
            );
        $(document)
            .on(
                "drop",
                function(e) {
                    e.preventDefault();
                    e.stopPropagation();
                }
            );

        // Для наследования
        Function.prototype.inheritsFrom = function(parentClassOrObject) {
            if (parentClassOrObject.constructor === Function) {
                //Normal Inheritance 
                this.prototype = new parentClassOrObject;
                this.prototype.constructor = this;
                this.prototype.parent = parentClassOrObject.prototype;
            } else {
                //Pure Virtual Inheritance 
                this.prototype = $.extend(this.prototype, parentClassOrObject);
                this.prototype.constructor = this;
                this.prototype.parent = parentClassOrObject;
            }
            return this;
        }

        // IE8 не умеет парсить дату, поэтому при создании дат
        // нужно пользоваться Date.fromISO
        var testDate = new Date("2011-06-02T09:34:29+02:00");
        if (!testDate || +testDate !== 1307000069000) {
            Date.fromISO = function(s) {
                var day,
                    tz,
                    rx = /^(\d{4}\-\d\d\-\d\d([tT ][\d:\.]*)?)([zZ]|([+\-])(\d\d):(\d\d))?$/,
                    p = rx.exec(s) || [];
                if (p[1]) {
                    day = p[1].split(/\D/);
                    for (var i = 0, L = day.length; i < L; i++) {
                        day[i] = parseInt(day[i], 10) || 0;
                    };
                    day[1] -= 1;
                    day = new Date(Date.UTC.apply(Date, day));
                    if (!day.getDate()) return NaN;
                    if (p[5]) {
                        tz = (parseInt(p[5], 10) * 60);
                        if (p[6]) tz += parseInt(p[6], 10);
                        if (p[4] === "+") tz *= -1;
                        if (tz) day.setUTCMinutes(day.getUTCMinutes() + tz);
                    }
                    return day;
                }
                return NaN;
            }
        } else {
            Date.fromISO = function(s) {
                if (!s) {
                    return null;
                }
                return new Date(s);
            }
        };

        !window.addEventListener && (function (WindowPrototype, DocumentPrototype, ElementPrototype, addEventListener, removeEventListener, dispatchEvent, registry) {
            WindowPrototype[addEventListener] = DocumentPrototype[addEventListener] = ElementPrototype[addEventListener] = function (type, listener) {
                var target = this;

                registry.unshift([target, type, listener, function (event) {
                    event.currentTarget = target;
                    event.preventDefault = function () { event.returnValue = false };
                    event.stopPropagation = function () { event.cancelBubble = true };
                    event.target = event.srcElement || target;

                    listener.call(target, event);
                }]);

                this.attachEvent("on" + type, registry[0][3]);
            };
            WindowPrototype[removeEventListener] = DocumentPrototype[removeEventListener] = ElementPrototype[removeEventListener] = function (type, listener) {
                for (var index = 0, register; register = registry[index]; ++index) {
                    if (register[0] == this && register[1] == type && register[2] == listener) {
                        return this.detachEvent("on" + type, registry.splice(index, 1)[0][3]);
                    }
                }
            };
            WindowPrototype[dispatchEvent] = DocumentPrototype[dispatchEvent] = ElementPrototype[dispatchEvent] = function (eventObject) {
                return this.fireEvent("on" + eventObject.type, eventObject);
            };
        })(Window.prototype, HTMLDocument.prototype, Element.prototype, "addEventListener", "removeEventListener", "dispatchEvent", []);
    });