define(["knockout"],
    function(ko) {
        var DepositedStatistics = function(statistics) {
            var self = this;
            statistics = statistics || {};
            self.Deleted = ko.observable(statistics.Deleted || 0);
            self.Deleting = ko.observable(statistics.Deleting || 0);
            self.Fixed = ko.observable(statistics.Fixed || 0);
            self.Fixing = ko.observable(statistics.Fixing || 0);
            self.DocumentsPerType = ko.observableArray(statistics.DocumentsPerType || []);
        }

        DepositedStatistics.prototype.refresh = function(data) {
            var self = this;
            data = data || {};
            self.Deleted(data.Deleted || 0);
            self.Deleting(data.Deleting || 0);
            self.Fixed(data.Fixed || 0);
            self.Fixing(data.Fixing || 0);
            self.DocumentsPerType(data.DocumentsPerType || []);
        }

        DepositedStatistics.prototype.getDocumentsCount = function (documentTypes) {
            var self = this;
            var result = 0;
            ko.utils.arrayForEach(documentTypes || [],
                function (type) {
                    var match = ko.utils.arrayFirst(self.DocumentsPerType() || [],
                    function (item) {
                        return item.Key === type;
                    });
                        if (match)
                            result += match.Value;
                });
            return result;
        }

        return DepositedStatistics;
    });