define(["jquery", "knockout", "tag-editor/jquery.tag-editor", "styles/custom/tag-editor-custom.css!"],
    function($, ko) {
        ko.bindingHandlers.multiInput = {
            init: function (el, valueAccessor, allBindings, viewModel) {
                var settings = ko.utils.unwrapObservable(allBindings().multiInput) || {};
                var values = settings.values;
                $(el).tagEditor({
                    initialTags: ko.unwrap(values),
                    forceLowercase: false,
                    onChange: function (field, editor, tags) {
                        if (ko.isObservable(values)) {
                            values(tags);
                        }
                    }
                });
                if (ko.isObservable(values)) {
                    values.subscribe(function (changes) {                        
                        ko.utils.arrayForEach(changes || [], function (change) {
                            if (change.status === "added") {
                                $(el).tagEditor("addTag", change.value, true);
                            }
                            if (change.status === 'deleted') {
                                $(el).tagEditor("removeTag", change.value, true);
                            }
                        });
                    },
                    null,
                    "arrayChange");
                }
            }
        }
    });