define(["jquery", "knockout", "chosen", "styles/custom/chosen-custom.css!"],
    function($, ko) {
        ko.bindingHandlers.multiselect = {
            init: function (el, valueAccessor, allBindings, viewModel) {
                var settings = ko.utils.unwrapObservable(allBindings().multiselect) || {};
                $.extend(settings,
                {
                    no_results_text: "Совпадений не найдено:"
                });
                $(el).chosen(settings);
                $(el).on('change', function (evt, params) {
                    var checked = !!params.selected;
                    var changedValue = checked ? params.selected : params.deselected;

                    if (checked) {
                        if (!allBindings.has("options"))
                            return;
                        var options = ko.utils.unwrapObservable(allBindings.get("options"));

                        var valueToAdd = null;
                        if (allBindings.has("optionsValue")) { // работаем с объектами
                            var optionsValue = allBindings.get("optionsValue");
                            var match = ko.utils.arrayFirst(options,
                                function (item) {
                                    return item[optionsValue] == changedValue; // ==, так как сравниваем со строкой
                                });
                            if (match)
                                valueToAdd = match[optionsValue];
                        } else { // работаем с простыми типами
                            valueToAdd = ko.utils.arrayFirst(options,
                                function (item) {
                                    return item == changedValue; // ==, так как сравниваем со строкой
                                });
                        }
                        // Ноль - валидное значение
                        if (valueToAdd == null || valueToAdd == undefined)
                            return;

                        if (allBindings.has("selectedOptions")) {
                            allBindings.get("selectedOptions").push(valueToAdd);
                        }
                    } else {
                        if (allBindings.has("selectedOptions")) {
                            var selectedOptions = allBindings.get("selectedOptions");
                            var valueToRemove = ko.utils.arrayFirst(ko.utils.unwrapObservable(selectedOptions),
                                function (item) {
                                    return item == changedValue; // ==, так как сравниваем со строкой
                                });
                            // Ноль - валидное значение
                            if (valueToRemove != null && valueToRemove != undefined) {
                                selectedOptions.remove(valueToRemove);
                            }
                        }
                    }
                });
                if (allBindings.has("options")) {
                    var options = allBindings.get("options");
                    if (ko.isObservable(options)) {
                        options.subscribe(function () {
                            $(el).trigger('chosen:updated');
                        });
                    }
                }

                if (allBindings.has("selectedOptions")) {
                    var selectedOptions = allBindings.get("selectedOptions");
                    if (ko.isObservable(selectedOptions)) {
                        selectedOptions.subscribe(function () {
                            $(el).trigger('chosen:updated');
                        });
                    }
                }
            }
        }
    });