define(
    [
        "scripts/statistics/document-statistics",
        "scripts/statistics/user-statistics"
    ],
    function(DocumentStatistics, UserStatistics) {
        var Statistics = function(statistics) {
            var self = this;
            statistics = statistics || {};
            self.DocumentStatistics = new DocumentStatistics(statistics.DocumentStatistics);
            self.UserStatistics = new UserStatistics(statistics.UserStatistics);
        }
        Statistics.prototype.refresh = function(data) {
            var self = this;
            self.DocumentStatistics.refresh(data.DocumentStatistics);
            self.UserStatistics.refresh(data.UserStatistics);
        }
        return Statistics;
    }
);