define(
    [
        "jquery",
        "knockout",
        "scripts/shared/single-value-field",
        "scripts/shared/multi-value-field",
        "scripts/shared/range-field",
        "scripts/shared/fileupload",
        "sa-utils"
    ],
    function($, ko, SingleValueField, MultiValueField, RangeField, FileUpload, utils) {
        var SearchForm = function(filter, document, options) {
            var self = this;
            options = options || {};

            self.SearchModes = {
                Text: 0,
                File: 1
            }

            self.SearchString = ko.observable(filter.SearchString);

            self.TopicSearchFileName = ko.observable(filter.TopicSearchFileName);
            self.TopicSearchDocumentId = ko.observable(filter.TopicSearchDocumentId);

            self.SearchMode = (filter.TopicSearchFileName || filter.TopicSearchDocumentId)
                ? ko.observable(self.SearchModes.File)
                : ko.observable(self.SearchModes.Text);

            var fields = filter.FormFields;
            self.FormFields = {
                Name: new SingleValueField(fields.Name),
                Author: new MultiValueField(fields.Author),
                PublicationDate: new RangeField(fields.PublicationDate, false, true),
                DocumentType: new MultiValueField(fields.DocumentType),
                Rank: new MultiValueField(fields.Rank),
                DissertationCouncilCode: new MultiValueField(fields.DissertationCouncilCode),
                VakCode: new MultiValueField(fields.VakCode)
            };

            self.IsExtendedFilterFilled = ko.pureComputed(function() {
                return ko.utils.unwrapObservable(self.FormFields.Name.Value) ||
                    (ko.utils.unwrapObservable(self.FormFields.Author
                            .Values) &&
                        self.FormFields.Author.Values().length > 0) ||
                    ko.utils.unwrapObservable(self.FormFields.PublicationDate.MinValue) ||
                    ko.utils.unwrapObservable(self.FormFields.PublicationDate.MaxValue) ||
                    (ko.utils.unwrapObservable(self.FormFields.DocumentType.Values) &&
                        self.FormFields.DocumentType.Values().length > 0) ||
                    (ko.utils.unwrapObservable(self.FormFields.Rank
                            .Values) &&
                        self.FormFields.Rank.Values().length > 0) ||
                    (ko.utils.unwrapObservable(self.FormFields.DissertationCouncilCode
                            .Values) &&
                        self.FormFields.DissertationCouncilCode.Values().length > 0) ||
                    (ko.utils.unwrapObservable(self.FormFields.VakCode
                            .Values) &&
                        self.FormFields.VakCode.Values().length > 0);
            });

            self.IsEmpty = ko.pureComputed(function() {
                return !self.SearchString()
                    && !self.TopicSearchFileName()
                    && !self.TopicSearchDocumentId()
                    && !self.IsExtendedFilterFilled();
            });

            self.IsExtended = ko.observable(self.IsExtendedFilterFilled());

            var fileUploadOptions = {
                Url: window.page.urls.Search.UploadFileForTopicSearch,
                AutoUpload: true,
                FormData: {},
                DropZoneSelector: options.dropZoneSelector,
                MaxFileSize: 5 * 1024 * 1024 // TODO: временно
            };
            $.extend(fileUploadOptions.FormData, window.page.token);
            self.FileUpload = new FileUpload(fileUploadOptions);
            self.FileUpload.FileName.subscribe(function(newValue) {
                // Если добавили файл, перейти в режим поиска по файлу                 
                if (!!newValue) {
                    self.setSearchMode(self.SearchModes.File);
                } else {
                    self.setSearchMode(self.SearchModes.Text);
                }
                self.TopicSearchFileName(newValue);
            });

            self.Years = function () {
                var result = [{ Key: null, Value: "" }];
                ko.utils.arrayForEach(ko.utils.range(1800, new Date().getFullYear()).reverse(),
                    function (item) {
                        result.push({ Key: item, Value: item });
                    });
                return result;
            }
        }

        SearchForm.prototype.cancelFileSearch = function (fileName) {
            var self = this;
            if (self.FileUpload.IsError()) {
                self.FileUpload.reset();
            } else if (self.FileUpload.IsLoading()) {
                self.FileUpload.cancelUploading();
                self.FileUpload.reset();
            } else {
                fileName = ko.unwrap(fileName);
                if (fileName) {
                    utils.postJson.call(self,
                        window.page.urls.Search.DeleteTopicSearchFile,
                        fileName,
                        function(result) {
                            self.FileUpload.reset();
                        });
                }
                else {
                    self.TopicSearchFileName("");
                    self.TopicSearchDocumentId(null);
                    self.setSearchMode(self.SearchModes.Text);
                }
            }            
            self.Document(null);
        }

        SearchForm.prototype.getFilterModel = function () {
            var self = this;
            if (!self.IsExtended()) {
                self.clearExtended();
            }
            return {
                SearchString: self.SearchMode() !== self.SearchModes.File ? self.SearchString() : "",
                TopicSearchFileName: self.SearchMode() === self.SearchModes.File ? self.TopicSearchFileName() : "",
                TopicSearchDocumentId: self.SearchMode() === self.SearchModes.File ? self.TopicSearchDocumentId() : null,
                FormFields: ko.toJS(self.FormFields)
            }
        }

        SearchForm.prototype.clearExtended = function () {
            var self = this;
            for (var field in self.FormFields) {
                if (self.FormFields.hasOwnProperty(field)) {
                    self.FormFields[field].clear();
                }
            }
        }

        SearchForm.prototype.clear = function () {
            var self = this;
            self.SearchString("");
            self.TopicSearchDocumentId(null);
            var fileName = self.TopicSearchFileName();
            if (fileName) {
                self.cancelFileSearch(fileName);
            }
            self.SearchMode(self.SearchModes.Text);
            self.clearExtended();
        }

        SearchForm.prototype.toggleExtended = function () {
            var self = this;
            self.IsExtended(!self.IsExtended());
        }

        SearchForm.prototype.setSearchMode = function (searchMode) {
            var self = this;
            self.SearchMode(searchMode);
        }
        return SearchForm;
    }
);