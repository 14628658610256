define(["jquery", "knockout", "moment"],
    function ($, ko) {
        ko.bindingHandlers.numberFormat = {
            update: function (element, valueAccessor) {
                var val = ko.unwrap(valueAccessor());
                var $element = $(element);
                if (val || val == 0) {
                    var resultString = val.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
                    $element.text(resultString);
                } else {
                    $element.text("");
                }
            }
        };
    });